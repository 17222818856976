let j = 1;

const en = {
    from: "From *",
    to: "To *",
    date: "Date *",
    time: "Time *",
    passangers: "Passengers *",
    contact_error_message: "This field is required",
    contact_success_message: "Thank you! Your message has been sent successfully",
    home_welcome: "Welcome to the World of Luxury",
    home_welcome_text:
        "As a premier ground transportation company in Switzerland, we specialize in private and VIP transfer services with professional drivers available 24/7. With years of trusted service, VIP-Transfers has become a preferred choice for both customers and partners. <br><br> Our fleet includes a variety of exclusive vehicles, such as Mercedes Benz S Classes, E Classes, V Classes, Vitos, Tesla Model X, and more, ensuring a safe and enjoyable ride for our valued customers. Whether it’s an executive private tour, airport transfer, or special event, we tailor our services to exceed your expectations. <br><br> Switzerland, known for its leisure and luxury, becomes even more enchanting with our VIP vehicles. Whether your visit is for leisure or business, we guarantee punctuality, safety, and discretion in your private transportation. <br><br> Our dedicated staff is here to assist you at every step, providing local expertise to make your experience seamless. Simply fill out the contact form with your details, and our team will provide you with the best quotes within 24 hours. <br><br> Embark on a VIP treatment with us, ensuring a secure and tranquil journey at unbeatable rates. Experience premium-class service with VIP-Transfers and make every mile of your trip memorable.",

    home_exclusive_text:
        "Navigate through business meetings, conferences, or important events in Switzerland with our exclusive transfers. Discover a world of elegance with our collection of Mercedes-Benz luxury cars. From the moment you step into one of these vehicles, you’ll experience unmatched luxury and sophistication. Professional drivers provide top-notch service, ensuring every transfer is smooth and unforgettable.",

    about_top:
        "VIP-Transfers is the very name of luxury transportation company based in Fribourg, Switzerland. It is one the several branches of ARATOURS SA, who delved into the travel and transfer world for very long years. <br><br> Our company has been running for more than 15 years while specialising in the field of private airport transfers, car disposal services, point to point private transfers in Switzerland and much more only with VIP premier vehicles. <br><br> VIP-Transfers is an on-going developing company and an expert in diverse service packages. Its reputation has been upholding by the means of a well-grounded, around-the-clock premium class transportation activities, as well as by gaining the trust and satisfaction of the customers, all along while offering competitive rates.",
    about_bottom:
        "By owning a huge variety of exclusive VIP fleet, we assure our valuable customers the experience of a safe, smooth and enjoyable drive. First Class sedans, minivans, minibuses, even electric cars are at disposal to meet and please our customers’ needs and expectations. <br><br> All our services are accompanied by expert enthusiast drivers, who are knowledgeable about every single corner of the areas that we serve in.  <br><br> All the staff of VIP-Transfers is always at your disposal to suggest, organize as well as, operate the private transfers to unveil the beauty of this unique corner of the world - Switzerland. <br><br> On regular basis, the technical maintenance of the vehicles guarantees the clients safety and stress-free door-to-door private and deluxe transportation.",
    about_big:
        "VIP-Transfers provides a great choice of a package for private transportation activities, well-designed for the individual and small group of friends or family’s transfers. <br><br> The premium class transportation, by means of a particular car model, fitted to the relevant occasion, would become for you one of the most fascinating and enjoyable parts of executive tours and private airport transfers, or of any other special events. <br><br> Our company’s successful business is linked with VIP fleet, a highly qualified dedicated working team, a diversity of exclusive services, as well as with a reliable rate policy and a constantly increasing number of valuable customers. <br><br> <i>Mission Statement:</i> <br> VIP-Transfers’ main mission is to build long-term relationships with its travellers. Also, to provide irreproachable customer services by pursuing business through innovation and advanced technology. <br><br> VIP-Transfers serves to fulfil the private transfers’ needs and expectations of passengers who are arriving in Switzerland and the nearby regions. It tends to make point-to-point transportations by making a difference in the Swiss market. The company takes care of its clients' comfort and tranquillity during their journey. <br><br> There are many reasons to make a private transportation order in advance to avoid any overcharges by the taxis’ services. Hence, for that, your valuable solution is VIP-Transfers. <br><br> Therefore, just request your online quote for one of VIP-Transfers’ provided services and receive the best prices for luxurious Mercedes-Benz Classes, Sprinters, as well as the electric cars - Tesla.",

    services: [
        {
            id: "0",
            url: "/images/businestransfer.svg",
            icon: "/images/services/vip_assist.svg",
            title: "VIP ASSISTANCE AT ZURICH INTERNATIONAL AIRPORT",
            title_url: "vip-assistance-at-zurich-international-airport",
            text:"We at AlpTransfer.com understand that convenience is key when it comes to your travel experience, which is why our dedicated drivers are ready to enhance your journey from the moment you arrive.",

            serviceImgTop: "/images/services/service-block-img-0.jpg",
            serviceImgBottom: "/images/services/vip_assistance.jpg",
            text_top: 
            "<b>Meet your driver right at the luggage pick-up area.</b> <br><br> We at AlpTransfer.com understand that convenience is key when it comes to your travel experience, which is why our dedicated drivers are ready to enhance your journey from the moment you arrive. <br><br> We are proud to present our new service – Airport VIP Assistance at Zurich International Airport. <br><br> No more navigating through crowded airports or worrying about hauling your luggage across terminals. Our professional drivers will meet you right at the luggage pickup area, ensuring a smooth transition from your flight to your final destination. Sit back, relax, and let us handle the logistics so you can focus on what matters most – enjoying your trip.",

            text_bottom:
                "<b>We offer:</b> <br><li> Personalized Service: Our drivers are committed to providing a personalized and attentive service tailored to your needs. Whether you're a solo traveller or a group, we have got you covered.</li> <li>Punctuality: We understand the importance of time, and our drivers are punctual and reliable. Count on us to get you to your destination on time, every time.</li> <li>Comfort and Safety: Our fleet of vehicles is well-maintained, ensuring a comfortable and safe journey. Your well-being is our top priority.</li> <li>Efficient Logistics: By meeting you directly at the luggage pickup area, we eliminate the hassle of finding transportation after a long flight. Your convenience is our mission.</li> <li>Experience the difference with AlpTransfer.com. Book your ride now, and let us take the stress out of airport transportation. Your journey starts the moment you land!</li> <br><br><br>Are you interested? Get in touch with us, and we would be happy to assist.",
            text_big:
                "---",
        },
        
        
        {
            id: "1",
            url: "/images/businestransfer.svg",
            icon: "/images/services/businestransfer.svg",
            title: "Business Transfers",
            desc: "Rely on us for seamless business transfers",
            title_url: "business_transfers",
            text:
                "Switzerland frequently hosts numerous world-renowned exhibitions, events, conferences, etc. For these occasions, VIP-Transfers ensures precise and timely business transfers. Book a business car transfer from our selection of luxurious vehicles such us Mercedes and Tesla.",

            serviceImgTop: "/images/services/service-block-img-0.jpg",
            serviceImgBottom: "/images/services/service-block-img-0-bottom.jpg",
            text_top:
                "VIP-Transfers is pleased to make a special business offer for your private transfers of the desired destinations. Today’s busy life makes us choose among safety, cost, reliability and quality while we offer you private business transfers with prestigious vehicles containing all these necessary components that tailored to your needs. <br><br>We have multilingual skilled drivers, who also speak English besides their main language, which is not strange in a country has four official languages. Just request your desired speaking language and we will gladly organize your private business transfer accompanied by a chauffeur based on that. <br><br>Begin your holiday in Switzerland or the business trip on a luxury basis by booking a relaxed and on time private transfer with VIP-Transfers. Our hassle-free private transfers are operated at any time and from everywhere in Switzerland. It could be from the airports, train stations or your accommodation to deliver you in safety to your desired destination.",
            text_bottom:
                "Our fleet is situated near the main Swiss airports of Geneva, Bern, Basel and Zurich to drive you through the Swiss Alps’ ski resorts and the cities.<br><br>Whenever your plane arrives, take your time and do not rush as all our pickups include free waiting time for 60 minutes at the airports and 15 minutes from everywhere else. <br><br>In case your pickup is from the airport and your flight has a delay, do not worry as we monitor it to assure your exact arriving time.<br><br>Once you are out of your plane, and after claiming your luggage you will receive a text that your driver is waiting for you at the arrival hall. He will greet you by a nameplate assigned with your name, later to lead you to the vehicle as well as assist you to place your suitcases and move towards your destination.",
            text_big:
                "Our private business transfer services are exactly what you require for a taste of luxury all along with comfort. We are not assuring only a high quality for you but also fixed prices in order to avoid you to overpay the taxi drivers, who are looking to overcharge the tourists. <br><br>Whatever your reason is for travel, we have a vehicle to suit your needs from 1 for up to 16 passengers. Therefore, get your ride now by just booking your private business transfer quickly via our simple online reservation portal and it will be confirmed within less than 24 hours only with VIP-Transfers’ professional staff. Furthermore, also the easiest ways are included to make your payment through by choosing one of the numerous payment methods.<br><br>Make your decision now and let VIP-Transfers take care of the rest. We guarantee you a private business transfer only for you and your party, a first-class and reliable service with maximum satisfaction to your chosen destination.<br><br>For further inquiries, please do not hesitate to contact us by e-mail or a simple phone call and our team will be happy to answer all your questions.",
        },
        {
            id: "2",
            url: "/images/cardisposal.svg",
            icon: "/images/services/cardisposal.svg",
            title: "Car at Disposal Services",
            desc: "Premium car services at your disposal",
            title_url: "car_disposal_services",
            text:"Book one of our premium cars accompanied with a driver at your disposal and enjoy a whole day discovery of the main sights of Switzerland. Simply select your preferred vehicle, specify the desired duration and destinations, and embark on a personalized journey.",

            serviceImgTop: "/images/services/service-block-img-1.jpg",
            serviceImgBottom: "/images/services/service-block-img-1-bottom.jpg",

            text_top:
                "Are you planning to spend your holidays or summer vacation in Switzerland? Either alone, with the family or for business, don’t worry we are VIP-Transfers glad to welcome you to our homeland in very special ways by offering a premium car service. <br><br> Full of pleasure we introduce for you private car disposal services in Switzerland. As a local transportation company in Switzerland, we will share with you every beautiful sight of our country through our knowledgeable drivers. <br><br> With a few simple steps, you will have your preferred type of luxurious car at your disposal for the whole day or for any period of time you need. ",
            text_bottom:
                "This the best way in order to get acquainted with the world-known destination – Switzerland. Your experience will start by greeting you right from one of the Swiss main airports to your hotel or to any other desired destination. Our services are based on punctuality, commitment, local experience, and most important on very reliable prices can be offered within the Swiss markets.   <br><br> VIP-Transfers’ vehicles are maintained to the highest levels of luxury. They are formally licensed, fully insured and featured with having free WiFi all along your path, thus, your journey promises you entertainment in every single minute of it. Besides, plenty of additional benefits including a free baby, child and booster seats, mineral water, etc.",
            text_big:
                "VIP-Transfers’ team and drivers are committed to providing the best and the most comfortable of what we do. <br><br> We are flexible enough to confirm your bookings even few hours before your sightseeing journey. Simply drop a request to our inbox and get a bright private way for all your transport needs. <br><br> We have an easy system to book your transfer online by filling your exact needs in the contact form: such as pick-up time, destinations and duration of the desired car disposal service and we will respond to you shortly, even less than 24 hours. <br><br> Briefly, why you should choose VIP-Transfers’ private car disposal services? <br><br> - Accuracy while assuring your reservation and by providing your service. <br> - In case the pick-up is from the airport, an hour free waiting time. <br> - Competitive prices for a highly qualified service. <br> - Best of Switzerland’s cities, resorts, Swiss Alps discoveries while benefiting of the local taste. <br> - Skilled driver-guide and premium vehicles. <br> - Variety of Mercedes-Benz cars, minivans, E-cars as Tesla Model X. <br> <br>  By booking the car disposal services with VIP-Transfers, from the airport, train station or your hotel, you will ensure your safe, comfortable and smooth private transfers to the preferred destinations throughout Switzerland and nearby countries. This private option is available in all the Swiss cities we operate in, so hire your premium car and professional driver with VIP-Transfers.",
        },

        {
            id: "3",
            url: "/images/ptp.svg",
            icon: "/images/services/ptp.svg",
            title: "Point-to-Point Transfers",
            desc: "Punctual point-to-point excellence",
            title_url: "point-to-point_transfers",
            text:
                "Experience door-to-door transfers in Switzerland, starting from your pick-up location to your destination, all in a private, comfortable, and luxurious car. Simply provide us with the pickup and drop-off addresses, along with the time, and we will take care of the rest.",
            serviceImgTop: 
            "/images/services/service-block-img-2.jpg",
            serviceImgBottom: 
            "/images/services/service-block-img-2-bottom.jpg",
            text_top:
                "Are you traveling to Switzerland on a business trip or for leisure? Both options and much more are guaranteed to you with VIP-Transfers during our door to door private services. <br /><br /> Point-to-Point private transportation service is operated by VIP-Transfers, which represents delivering you from point A to point B. <br /><br /> By booking this private option you will experience on-time and reliable door-to-door transfers accompanied by a skillful and well-mannered driver. <br /><br /> VIP Transfer’s office is located in the heart of Switzerland – Fribourg, while having its fleet based near the main Swiss airports of Zurich, Geneva, Bern and Basel to make sure that we will be at your disposal any time you ask for our private transportation services. ",
            text_bottom:
                "As we are specialised in private transfers from all the airports of Switzerland to your desired destination in Switzerland and the neighbouring regions. All our operated transfers are highly affordable for luxurious services and all-inclusive. <br /><br /> Our fleet consists of an extensive range of deluxe vehicles including the following styles: The eco-friendly Tesla Model X, the famous Mercedes-Benz sedans, minivans, sprinters among others. <br /><br /> All you need is just to provide us with your exact location and the pickup time, as well as your wished destination to reach. Next, VIP-Transfers’ support team will handle your request and follow up with all the needed steps until you receive a confirmed reservation voucher and later, to be served with the most relaxing journey.<br /><br /> With VIP-Transfers you will feel the sense of comfort, starting from the greeting by your friendly driver, during the provided VIP transportation service until you reach your holiday destination. ",
            text_big:
                "Take your private ride in our legally registered and comfortable vehicles, as all the cars are fitted by very special features, which are a free WiFi in order to entertain your journey more, fully air-conditioned, leather restful seats while your luggage is accommodated in the car’s boot, among others. <br /><br /> You are promised absolute hassle-free trip without any detours of your route, you will reach any wished spot without any delay. This form of private transportation service is best matching for business travellers due to their fixed meeting schedules.  <br /><br /> VIP-Transfers encourage you to make your early booking with us in order to receive guaranteed and discounted rates. Besides, you will avoid any overcharges from the odd taxis and being unsafe while taking a ride with not professional chauffeurs. <br /><br /> Thus, you are welcome to book online through our easy and simple system or send us your travel details and we will email you back with a confirmation voucher. VIP-Transfers is your best way to discover Switzerland on the most comfortable and safe bases with our luxurious vehicles and professional drivers. <br /><br />  Looking forward to greeting you in the heart of Europe – Switzerland. <br /><br />",
        },

        {
            id: "4",
            url: "/images/airport.svg",
            icon: "/images/services/airport.svg",
            title: "Airport Transfer Services",
            desc: "Flight monitoring with free waiting time",
            title_url: "airport_transfer_services",
            goTO: "/services",
            text:
                "This service offers numerous benefits, including the acceptance of last-minute bookings and a meet-and-greet service at the arrival hall of airports. Our knowledgeable driver will be holding a nameplate assigned with your name, will greet you, and will help with your luggage.",
            serviceImgTop: "/images/services/service-block-img-3.jpg",
            serviceImgBottom: "/images/services/service-block-img-3-bottom.jpg",
            text_top:
                "Whether you are travelling on business or on leisure, you are welcome and invited to experience VIP-Transfers’ reliable and high-class transportation services from and to the airports of Switzerland. <br><br> As one of the most important things whenever you plan to travel abroad and discover the world is to assure your private airport transfer service. At the moment, that you arrive in a foreign country while being very exhausted after your long flight, all you will need is the feeling of secure and comfortable during your next private journey. Thus, we are VIP-Transfers – a premier car services company in Switzerland, who is glad to serve your private airport transfer with one of its wide selection of luxurious VIP vehicles. <br><br> VIP-Transfers’ office is based in Fribourg - the heart of Switzerland and has fleet situated near the main airports of Switzerland, in the cities of Zurich, Geneva, Bern, and Basel. Therefore, we are able to confirm your order within a few hours before your arrival, thus, the early and late pickups are no problem. ",
            text_bottom:
                "Our deluxe fleet consists of Mercedes-Benz sedans, luxurious minivans, sprinters and electric cars like Tesla Model X. <br><br> The company’s private transfers are available every day within all the cantons of Switzerland and the bordering regions. Even during the holidays, both VIP-Transfers’ staff and drivers are at your disposal to deliver your desired private transfer. <br><br> Thus, book with confidence your easy and smooth transfer in order to start your vacation on deluxe bases. We organise all kind of private transportation services, starting from the private airport and city transfers to door-to-door transfers and private tours, as well as daily excursions. ",
            text_big:
                "Our service starts when your driver waits for you an hour free of charge after your plane lands. Later, he greets you at the arrival hall after you finish claiming your luggage with plate holds the leading passenger’s name. Our well-mannered driver will escort you to your car, take care of your luggage efficiently and later, drive you to your required destination. Our private airport transfers also have vice versa transfers from your accommodation to the airport. <br><br> All along of your journey, you will never feel how the time will pass quickly, as you will have unlimited free WiFi to entertain you. As all our vehicles are featured by having a WiFi internet to keep you connected along the transfer’s path. <br><br> Make an online reservation or send your booking details through a request by email and you will get the confirmation in less than 24 hours. A full refund applies for cancellations up to 48 hours before the transfer’s time. <br><br> All our provided services are fast and reliable, hence, make your trip exciting with VIP-Transfers!",
        },

        {
            id: "5",
            url: "/images/city.svg",
            icon: "/images/services/city.svg",
            title: "City Tour & Excursion",
            desc: "Embark on your scenic journey with us",
            title_url: "city_tour_and_excursion",

            goTo: "/services",
            text:
                "Explore hidden gems with VIP-Transfers. Starting from your accommodation with a driver-guide, choose from various private trips, spanning cities, mountains, boat trips, and more. The experience concludes with a private return to the starting point.",
            serviceImgTop: "/images/services/service-block-img-4.jpg",
            serviceImgBottom: "/images/services/service-block-img-4-bottom.jpg",
            text_top:
                "Switzerland – the beautiful country which is situated in the heart of Europe invites all the tourists to interesting private city tours and excursions in each corner of it. <br><br> The fabulous Swiss Alps is the best destination for all type of travellers, ski lovers, adventure seekers. Thus, VIP-Transfers all along with its other branch of Switzerland Tour are glad to offer you the best of Switzerland during private daily trips operated with luxurious cars. <br><br> We are as a local travel agency and transportation company glad to share the history, beauty, the wonderland of mountains and lakes of Switzerland during our provided private excursions and tours.",
            text_bottom:
                "Our well organized private excursions start from your hotel where your driver-guide greets you to start your journey through the Swiss Alps or to depart and delve into another Swiss city. We are covering all the famous sights in Switzerland as well as in the neighbouring countries. <br><br> Also, we arrange city tours of Switzerland which present walking tours for two hours with a professional guide, who speaks your desired language. During the discovery tour, you get acquainted with the cultural and historical facts of the Swiss old towns, castles, as well as the other main sights.",
            text_big:
                "All the trips are operated with luxurious vehicles as we care of your comfort during the long journey. As an example, to immerse yourself in the real Swiss beauty is to take a scenic drive through the Alps with the most deluxe Electric car Tesla Model X. One of the amazing and unique features of Tesla is its largest panoramic windshield which allows you to enjoy every single natural view. On top of this, by booking a private trip with Tesla Model X, you will make sure to have a silent, without any noise ride. After ending your daily trip, the driver-guide will conclude your restful day with a private return to the initial meeting point. <br><br> Our team became an expert during the years of arranging and serving the private excursions and tours. Thus, we can advise you with the most beautiful and worth to see spots in Switzerland. Just a simple visit to Switzerland Tour’s website you will get acquainted with the variety of choices starting from the main Swiss cities. However, we are always eager to also customize for you any desired private excursion according to your wishes.  <br><br> Your private trips with VIP-Transfers and Switzerland Tour are equipped with the relaxing needs as well as an entertainment, as all the way of your journey you will enjoy the advantage of free WiFi in all our vehicles. Hence, just capture your moments and keep your friends posted by sharing your fancy excursion’s details in Switzerland. <br><br> Feel free to contact us to get advice and assistance while planning your visit to Switzerland on luxurious bases, starting from the private transportation services to the daily excursions and tour packages, as we are always at your disposal to inspire your stay!",
        },

        {
            id: "6",
            url: "/images/handicapped.svg",
            icon: "/images/services/handicapped.svg",
            title: "Handicapped Services",
            desc: "Specialized assistance and support",
            title_url: "handicapped_services",
            text:
                "VIP-Transfers caters to wheelchair travelers, utilizing a wide range of fleet vans designed for mobility impaired and special needs clients. Our dedicated drivers are trained to assure a comfortable and secure ride, with an appropriate customer care and treatment.",
            serviceImgTop: "/images/services/service-block-img-5.jpg",
            serviceImgBottom: "/images/services/service-block-img-5-bottom.jpg",
            text_top:
                "VIP-Transfers offers very special assistance and support for travellers who are disabled or mobility reduced due to age. <br><br> Our company is a local transportation services provider in Switzerland, which serves all kind of private transfer requests, including the ones for people with special needs. <br><br> VIP-Transfers caters to wheelchair travellers, utilizing a wide range of vans designed for mobility impaired and with special needs clients at very affordable rates. <br><br> Our team starring from the office staff to the well-trained drivers are dedicated and constantly drilled to assure comfortable and secure point-to-point ride, with appropriate customer care and treatment. They will pick you up from your front door at the agreed time to deliver you for your wished destination.",
            text_bottom:
                "All you have to do is to get in touch with our team and we will take great care of you! <br><br> We arrange our private transfer services not only in all cantons of Switzerland but also in the neighbouring regions. <br><br> Be it an airport private transfer or door-to-door transfer, we make sure to guarantee the safety and comfort of every single customer separately. During the years of assisting the customers, VIP-Transfers became an expert in providing high-level services. Thus, it has proudly earned the trust and loyalty of its clients.",
            text_big:
                "Those dedicated efforts are in order to achieve our main goal, which is to make your trip smooth and reliable by reducing the stress level to the maximum. <br><br> VIP-Transfers is glad to serve the disabled travellers with the special handicapped cars. As we have an appropriate vehicle for people in a wheelchair.  <br><br> This vehicle is adapted to international standards and fitted with the latest safety devices. Our drivers are trained to work with wheelchair users. Most of them can speak fluent English so that communicating with them will not be a problem. <br><br> Our group of experienced chauffeurs has sufficient knowledge regarding different types of physical disabilities. Thus, they will assist you along with your luggage by carrying it from the airport as well as private residence pick-ups, will load and unload them into and from the vans. They will make sure to provide a comfortable and secure point-to-point ride, with an appropriate customer care and treatment. <br><br> Book your private transfer for handicapped persons and those with reduced mobility by choosing one of our luxurious air-conditioned vans or minibuses. Entertain all the way of your journey by taking advantage of the free WiFi services. <br><br> Our vehicles allow the passengers in wheelchairs to travel with a stimulating experience at any given time. They are specially designed with an access ramp. These vehicles are equipped with an electric ramp, which makes it easier for the clients to access the car from the road or pavement. <br><br> You are welcome to make a booking in advance through our online site. Alternatively, VIP-Transfers’ helpful team is always at your disposal to answer all your inquiries and clarify your doubts. Later, we will confirm your request with pleasure, and organise your preferred private transfer in Switzerland.",
        },
    ],

    features: [
        {name:'ecar', text:'E-Car'},
        {name:'vipv', text:'VIP Vehicle'},
        {name:'vipmv', text:'VIP Minivan'},
        {name:'vipmb', text:'VIP Minibus'},
        {name:'4mat', text:'4Matic'},
        {name:'lights', text:'Roof lights '},
        {name:'leather', text:'Leather seats'},
        {name:'ventil', text:'Ventilated seats'},
        {name:'reclining', text:'Reclining seats'},
        {name:'5str', text:'5-Star safery'},
        {name:'fridge', text:'Refrigerator'},
        {name:'massage', text:'Massage seats'},
        {name:'panoramic', text:'Panoramic windshield'},
        {name:'falcon', text:'Falcon wing doors'},
        {name:'adoors', text:'Automatic doors'},
        {name:'scenic', text:'Scenic window'},
        {name:'hybrid', text:'Hybrid electrical sys.'},
        {name:'dvd', text:'DVD player'},
        {name:'air', text:'Air-conditioned'},
        {name:'12v', text:'12V power slot'},
        {name:'230v', text:'230V power slot'},
        {name:'coffee', text:'Coffee warmer'},
        {name:'water', text:'Mineral water'},
        {name:'wifi', text:'WiFi on board'},
        {name:'table', text:'Table for 4'},
        {name:'folding', text:'Folding table'},
    ],

    cars: [
        {
            id: j,
            img: "/images/gle/gle.png",
            pax: "3",
            lug: "3",
            type: "HYBRID",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz GLE 450",
            title_url: "mb_gle_450_hybrid",
            features: ['vipv','4mat','leather','ventil','panoramic','air','hybrid','wifi','water'],
            text:
                "At VIP-Transfers, we continuously strive to enhance your travel experiences and create unforgettable journeys. That’s why we are thrilled to introduce the latest addition to our esteemed fleet - the Mercedes-Benz GLE 450 Hybrid.\n" +
                "\n" +
                "This vehicle embodies elegance and power, commanding attention with its sleek design and unparalleled craftsmanship. As a hybrid vehicle, it exemplifies our commitment to sustainability by minimizing emissions and reducing our carbon footprint.\n" +
                "\n" +
                "Step into the spacious and refined interior, where modern design meets cutting-edge technology. With three comfortable passenger seats, this vehicle is perfect for accommodating small groups or families. Enjoy the advanced infotainment systems, intuitive controls, and smart features designed to elevate your travel experience. The Mercedes-Benz GLE 450 Hybrid reinforces our commitment to delivering the highest standards of luxury, comfort, and sustainability.\n" + 
                "\n" +
                "Book your next exclusive transfer with VIP-Transfers and experience the extraordinary fusion of luxury and eco-consciousness with the Mercedes-Benz GLE 450 Hybrid.",
            galeyiImg: [
                { url: "/images/gle/gle_1.jpg" },
                { url: "/images/gle/gle_2.jpg" },
                { url: "/images/gle/gle_3.jpg" },
                { url: "/images/gle/gle_4.jpg" },
                { url: "/images/gle/gle_5.jpg" },
                { url: "/images/gle/gle_6.jpg" },
                { url: "/images/gle/gle_7.jpg" },
                { url: "/images/gle/gle_8.jpg" },
                { url: "/images/gle/gle_9.jpg" },
            ],
            text_top:
                "At VIP-Transfers, we continuously strive to enhance your travel experiences and create unforgettable journeys. That’s why we are thrilled to introduce the latest addition to our esteemed fleet - the Mercedes-Benz GLE 450 Hybrid.<br><br> This vehicle embodies elegance and power, commanding attention with its sleek design and unparalleled craftsmanship. As a hybrid vehicle, it exemplifies our commitment to sustainability by minimizing emissions and reducing our carbon footprint.<br><br>Step into the spacious and refined interior, where modern design meets cutting-edge technology. With three comfortable passenger seats, this vehicle is perfect for accommodating small groups or families. Enjoy the advanced infotainment systems, intuitive controls, and smart features designed to elevate your travel experience. The Mercedes-Benz GLE 450 Hybrid reinforces our commitment to delivering the highest standards of luxury, comfort, and sustainability.<br><br>Book your next exclusive transfer with VIP-Transfers and experience the extraordinary fusion of luxury and eco-consciousness with the Mercedes-Benz GLE 450 Hybrid.",
        },
        
        
        {
            id: j++,
            img: "/images/gle350/gle_350.png",
            pax: "3",
            lug: "3",
            type: "HYBRID",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz GLE 350",
            title_url: "mb_gle_350_hybrid",
            features: ['vipv','4mat','hybrid','leather','ventil', 'massage','air','wifi','water'],
            text:
                "Experience Unparalleled Luxury with the Mercedes-Benz GLE 350de in Vip Transfer’s Elite Fleet.\n" +
                "\n" +
                "Welcome to Vip Transfer, where we redefine private transfers in Switzerland with our exceptional fleet. We are thrilled to introduce the prestigious Mercedes-Benz GLE 350de. \n" +
                "\n" +
                "The Mercedes-Benz GLE 350de sets new standards in luxury and performance. With its sleek design, powerful engine, and advanced technology, this vehicle embodies sophistication and elegance. Combining the best comfort and efficiency, the GLE 350de features a hybrid engine that delivers power and exceptional fuel economy. \n" + "\n" +
                " Step inside the spacious, meticulously crafted interior, where refined materials and state-of-the-art features await. Luxuriate in the plush seating, advanced infotainment system, and intuitive controls that elevate your travel experience. The GLE 350de offers comfortable seating for up to three passengers, ensuring a comfortable journey for everyone. Whether you’re traveling solo or with a group, the plush seats provide the utmost comfort and support throughout your ride. \n" + "\n" +
                " In addition to the generous seating, the Mercedes-Benz GLE 350de boasts ample luggage space, accommodating all your belongings with ease. Whether you’re traveling for business or pleasure, rest assured that your luggage will be well taken care of during your private transfer.",
            galeyiImg: [
                { url: "/images/gle/gle_1.jpg" },
                { url: "/images/gle/gle_2.jpg" },
                { url: "/images/gle/gle_3.jpg" },
                { url: "/images/gle/gle_4.jpg" },
                { url: "/images/gle/gle_5.jpg" },
                { url: "/images/gle/gle_6.jpg" },
                { url: "/images/gle/gle_7.jpg" },
                { url: "/images/gle/gle_8.jpg" },
                { url: "/images/gle/gle_9.jpg" },
            ],
            text_top: 
            "Experience Unparalleled Luxury with the Mercedes-Benz GLE 350de in Vip Transfer’s Elite Fleet. <br><br> Welcome to Vip Transfer, where we redefine private transfers in Switzerland with our exceptional fleet. We are thrilled to introduce the prestigious Mercedes-Benz GLE 350de. <br><br> The Mercedes-Benz GLE 350de sets new standards in luxury and performance. With its sleek design, powerful engine, and advanced technology, this vehicle embodies sophistication and elegance. Combining the best comfort and efficiency, the GLE 350de features a hybrid engine that delivers power and exceptional fuel economy. <br><br> Step inside the spacious, meticulously crafted interior, where refined materials and state-of-the-art features await. Luxuriate in the plush seating, advanced infotainment system, and intuitive controls that elevate your travel experience. The GLE 350de offers comfortable seating for up to three passengers, ensuring a comfortable journey for everyone. Whether you’re traveling solo or with a group, the plush seats provide the utmost comfort and support throughout your ride. <br><br> In addition to the generous seating, the Mercedes-Benz GLE 350de boasts ample luggage space, accommodating all your belongings with ease. Whether you’re traveling for business or pleasure, rest assured that your luggage will be well taken care of during your private transfer. ",
        },

        {
            id: j++,
            img: "/images/s_class/s_500.png",
            pax: "3",
            lug: "2",
            type: "First Class",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz S 500",
            title_url: "mb_s_500",
            features: ['vipv','4mat','leather','ventil','massage','air','hybrid','wifi','water'],
            galeyiImg: [
                { url: "/images/s_class_500/s500_1.webp" },
                { url: "/images/s_class_500/s500_2.webp" },
                { url: "/images/s_class_500/s500_6.webp" },
                { url: "/images/s_class_500/s500_4.webp" },
                { url: "/images/s_class_500/s500_10.webp" },
            ],
            text_top:
                "Welcome to a new level of sophistication and comfort with Vip Transfer’s Mercedes-Benz S500 First Class. Our premium fleet now features this exceptional vehicle, redefining the standard for luxurious travel and private transfers in Switzerland. <br><br>The Mercedes-Benz S500 First Class boasts sumptuous leather seats, providing an unmatched level of comfort. Whether you’re traveling solo or with companions, these seats are designed to make your journey unforgettable.<br><br> Stay connected even while on the move. Our vehicle is equipped with onboard WiFi, allowing you to work or stay in touch with loved ones throughout your journey. We understand the importance of staying refreshed during your travels. Enjoy the complimentary water to keep you hydrated and comfortable throughout your ride. Our advanced air conditioning system guarantees that you travel in perfect climate-controlled comfort, no matter the season or weather conditions. ",
        },
        
        {
            id: j++,
            img: "/images/s_class/s_400.png",
            pax: "3",
            lug: "2",
            type: "HYBRID",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz S 400",
            title_url: "mb_s_400",
            features: ['vipv','leather','air','dvd','panoramic','230v','water','wifi'],
            galeyiImg: [
                { url: "/images/s_class/sclass_1.jpg" },
                { url: "/images/s_class/sclass_2.jpg" },
                { url: "/images/s_class/sclass_3.jpg" },
                { url: "/images/s_class/sclass_4.jpg" },
                { url: "/images/s_class/sclass_5.jpg" },
                { url: "/images/s_class/sclass_6.jpg" },
            ],
            text_top:
                "The Mercedes-Benz S Class is the true shape of a stylish luxury car. It is an exclusive first-class vehicle for business transfers, as well as an exquisite center of well-being. <br><br> Previously known as “Sonderklasse” (translated from German as “special class”), Mercedes-Benz S Class introduces a wide range of limousine vehicles, which are being produced by the world-famous German automaker Mercedes-Benz. <br><br> Nowadays, people prefer to travel from one point to another not only with comfort but also in style. Thus, VIP-Transfers is here to organize your luxurious private transfers from the Swiss main airports of Geneva, Zurich, Bern, and Basel within and throughout the borders of Switzerland. <br><br> The synonym for S Class cars has always been and continues to remain the word “Luxury”. Also, S Class is ranking as the best-selling luxury sedan of the world. The S Class represents many of the company’s latest innovations, including drivetrain technologies, interior features, and safety systems.",
        },


        {
            id: j++,
            img: "/images/tesla/tesla_x.png",
            pax: "4",
            lug: "4",
            type: "ECO",
            icon: "/images/fleet/tesla.svg",
            iconHover: "/images/fleet/tesla-hover.svg",
            title: "Tesla X Dual Motor",
            title_url: "tesla_model_x",
            features: ['ecar','vipv','4mat','5str','leather','panoramic','falcon','air','wifi','water'],
            galeyiImg: [
                { url: "/images/tesla/tesla-x-1.webp" },
                { url: "/images/tesla/tesla-x-3.webp" },
                { url: "/images/tesla/tesla-x-4.webp" },
                { url: "/images/tesla/tesla-x-5.webp" },
                { url: "/images/tesla/tesla-x-6.webp" },
                { url: "/images/tesla/tesla-x-7.webp" },
                { url: "/images/tesla/tesla-x-8.webp" },
                { url: "/images/tesla/tesla-x-9.webp" },
            ],
            text_top:
                "Tesla is the safest, quickest and most qualified sport utility electric car, especially Tesla Model X. Being featured with 4MATIC all-wheel drive to control every wheel all the time provides overwhelming safety especially on rain and snow conditions.<br><br> It is the ideal which holds 5-star safety ratings across every category officially proved by the National Highway Traffic Safety Administration of the US. <br><br> VIP-Transfers invites you to start your happy go lucky journey in Switzerland by booking private transfer with Tesla Model X, while being greeted by the professional driver at the arrival hall of Zurich International Airport. <br><br> Easily you will acknowledge him as he will be holding your name on his signboard. Take your comfortable place on one of the four leather seats of the interior and achieve your desired destination on the smoothest basis.",
        },


        {
            id: j++,
            img: "/images/teslaS/tesla_s.png",
            pax: "3",
            lug: "3",
            type: "ECO",
            icon: "/images/fleet/tesla.svg",
            iconHover: "/images/fleet/tesla-hover.svg",
            title: "Tesla S Dual Motor",
            title_url: "tesla_model_s",
            features: ['ecar','vipv','4mat','5str','leather','leather','air','water','wifi'],
            galeyiImg: [
                { url: "/images/teslaS/tesla_1.jpg" },
                { url: "/images/teslaS/tesla_2.jpg" },
                { url: "/images/teslaS/tesla_3.jpg" },
                { url: "/images/teslaS/tesla_4.jpg" },
                { url: "/images/teslaS/tesla_5.jpg" },
                { url: "/images/teslaS/tesla_6.jpg" },
            ],
            text_top:
                "VIP-Transfers’ fleet has been supplemented by Tesla Model S, an ecological and electric sportscar for stress-free and enjoyable transfers. Your brand-new private service representative is also the fastest production car ever. All-wheel-drive, ability to develop speed within seconds, thus Tesla, American automotive and energy company, speaks directly to its quality. There are some main assets that demonstrate the superiority of Tesla and create a memorable and pleasant atmosphere for your private transfer, chauffeured disposal service and personal journey with VIP-Transfers.",
        },

        
        
        {
            id:j++,
            img: "/images/e_class/e_class.jpg",
            pax: "3",
            lug: "3",
            type: "4MATIC",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz E Class",
            title_url: "mb_e_class",
            features: ['vipv','4mat','leather','12v','air','wifi','water'],
            galeyiImg: [
                { url: "/images/e_class/eclass_1.jpg" },
                { url: "/images/e_class/eclass_4.jpg" },
                { url: "/images/e_class/eclass_3.jpg" },
                { url: "/images/e_class/eclass_5.jpg" },
                { url: "/images/e_class/eclass_6.jpg" },
                { url: "/images/e_class/eclass_7.jpg" },
            ],
            text_top:
                "Mercedes-Benz E Class is the best-selling executive car, thanks to all the features it offers while gifting its travellers the real meaning of private relaxed transfer. Being featured with 4MATIC all-wheel drive to control every wheel all the time provides overwhelming safety especially on rain and snow conditions.<br><br> VIP-Transfers is at your disposal to assure you luxurious private rides with a different unique style, based on the highest levels of comfort, smooth and safety. <br><br> All our team members are here to facilitate your private airport transfers in Switzerland with one of our masterpieces - Mercedes-Benz E Class. <br><br> We offer a wide range of luxury vehicles tailored to your needs. Besides, drivers, whose experiences reached the heights levels of progress in order to serve you in deluxe modes. <br><br> Thus, the fourth generation of the Mercedes-Benz E Class range, form the biggest section of our fleet as your comfort is our duty. These are premium sedan cars produced by the German automaker Mercedes-Benz, which are in a very high demand nowadays.",
        },

        {
            id:j++,
            img: "/images/eqv/eqv.png",
            pax: "6",
            lug: "6",
            type: "ECO",            
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz EQV 300",
            title_url: "mb_eqv_300",
            features: ['vipv','ecar','leather','reclining','adoors','air','12v','wifi','water'],
            goTO: "/services",
            galeyiImg: [
                { url: "/images/eqv/eqv_1.webp" },
                { url: "/images/eqv/eqv_2.webp" },
                { url: "/images/eqv/eqv_3.webp" },
                { url: "/images/eqv/eqv_4.webp" },
                { url: "/images/eqv/eqv_5.webp" },
            ],
            text_top: "The Mercedes Benz EQV 300 is a state-of-the-art luxury vehicle that boasts exceptional comfort, space, and style. With room for up to six passengers, this fully electric vehicle is perfect for families, small groups, and business travelers. <br></br> This eco-friendly vehicle is powered by an advanced electric motor, which delivers a smooth and silent ride with zero emissions. The spacious interior is equipped with the latest technology, including a touchscreen infotainment system, premium sound system, and climate control. Their luxury seating, air conditioning and heating system guarantees a pleasant journey in all weathers. Here at Vip Transfer, we’re committed to your comfort and aim to make your trip one to remember. We also offer complimentary refreshments to help you relax and enjoy your ride. If you have any special requests or dietary requirements, please inform us in advance so we can do our best to accommodate you. <br></br> The new Mercedes Benz EQV 300 is also equipped with a range of advanced safety features, such as lane departure warning, adaptive cruise control, and parking assist, making it one of the safest vehicles on the road." },



        {
            id:j++,
            img: "/images/vv_exclusive/vv_exclusive.png",
            pax: "6",
            lug: "6",
            type: "EXCLUSIVE",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz V Class",
            title_url: "mb_v_exclusive_new",
            features: ['vipmv','4mat','adoors','leather','reclining','fridge','folding','coffee','air','230v','wifi','water'],
            goTO: "/services",
            galeyiImg: [
                { url: "/images/vv_exclusive/vv_exclusive_1.jpg" },
                { url: "/images/vv_exclusive/vv_exclusive_2.jpg" },
                { url: "/images/vv_exclusive/vv_exclusive_3.jpg" },
                { url: "/images/vv_exclusive/vv_exclusive_4.jpg" },
                { url: "/images/vv_exclusive/vv_exclusive_5.jpg" },
                { url: "/images/vv_exclusive/vv_exclusive_6.jpg" },
                { url: "/images/vv_exclusive/vv_exclusive_7.jpg" },
                { url: "/images/vv_exclusive/vv_exclusive_8.jpg" },
                { url: "/images/vv_exclusive/vv_exclusive_9.jpg" },
                { url: "/images/vv_exclusive/vv_exclusive_10.jpg" },
                { url: "/images/vv_exclusive/vv_exclusive_11.jpg" },
            ],
            text_top: "Book a Mercedes-Benz V Class Exclusive for your Switzerland transfers, a luxury minivan designed for those who value comfort, elegance, and business functionality. Whether you’re heading to a corporate meeting, exploring Switzerland’s breathtaking landscapes, or enjoying a family holiday, this VIP transfer ensures a premium journey tailored to your needs.<br></br> Step into an exclusive Brabus Business Plus Interior, where luxury meets practicality. The spacious cabin features reclining leather seats with massage and climate control functions, ensuring ultimate relaxation throughout your trip. The folding tables provide a convenient workspace for business travelers, allowing you to stay productive while on the move.<br></br> Stay connected with high-speed WiFi, charge your devices with USB ports and a 230V power slot, and enjoy refreshing drinks from the onboard refrigerator. The automatic sliding doors offer effortless entry and exit, while the ambient lighting creates a sophisticated atmosphere.<br></br> With ample space for passengers and luggage, the Mercedes-Benz V Class Exclusive is perfect for executive transfers, airport pickups, corporate travels, and leisure trips. Whether you need a reliable transfer to a business meeting or a smooth ride to your ski resort, this vehicle offers the perfect balance of luxury, convenience, and efficiency.<br></br> Book your private transfer with VIP-Transfers today and experience first-class travel like never before!" },



        {
            id:j++,
            img: "/images/v_exclusive/v_exclusive.png",
            pax: "6",
            lug: "6",
            type: "EXCLUSIVE",            
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz V Class",
            title_url: "mb_v_exclusive",
            features: ['vipmv','4mat','leather','reclining','adoors','air','230v','wifi','water'],
            goTO: "/services",
            text:
                "Planning business trips, special occasions and wedding ceremonies, take an important place in our life, and the right choice of the right partner is the most role-playing factor in the quality and effectiveness of fulfilling any of preferred services.\n" +
                "\n" +
                "VIP-Transfers, is an on-going developing company, specialized in diverse service packages, and upholds its reputation by means of a well- grounded, around- the-clock Premium Class Transportation activities, as well as a customer trust and satisfaction, and competitive rates.\n" +
                "\n" +
                "Access to a huge variety of exclusive vehicle fleet, assures valuable customers to experience safe and enjoyable drive. First Class sedans, minivans, minibuses, coaches, retro cars and limousines, even helicopters and planes are at disposal to meet and satisfy our customers’ needs and expectations. On regular basis, technical maintenance of the vehicle fleet, guarantees the clients safety and stress free door-to-door transportation.",
            galeyiImg: [
                { url: "/images/v_exclusive/vclass_1.jpg" },
                { url: "/images/v_exclusive/vclass_2.jpg" },
                { url: "/images/v_exclusive/vclass_3.jpg" },
                { url: "/images/v_exclusive/vclass_4.jpg" },
                { url: "/images/v_exclusive/vclass_5.jpg" },
                { url: "/images/v_exclusive/vclass_6.jpg" },
                { url: "/images/v_exclusive/vclass_7.jpg" },
                { url: "/images/v_exclusive/vclass_8.jpg" },
                { url: "/images/v_exclusive/vclass_9.jpg" },
                { url: "/images/v_exclusive/vclass_10.jpg" },
                { url: "/images/v_exclusive/vclass_11.jpg" },
                { url: "/images/v_exclusive/vclass_12.jpg" },
            ],
            text_top:
                "At Vip-Transfers, we pride ourselves on providing top-of-the-line transfer services to our esteemed clients. We are thrilled to introduce our latest addition to our fleet, the Mercedes-Benz V-Class with Exclusive Brabus Business Plus Interior. This vehicle is the epitome of luxury and comfort, designed to take your transfer experience to the next level.<br><br> BRABUS is a great example of innovative and stylishly designed vehicles with integrated business and multimedia systems, and now, you can enjoy these features while traveling in Switzerland with us.<br><br> With a spacious and elegant passenger compartment that boasts two multi-adjustable business seats, you’ll feel like royalty throughout your journey. The seats come with a range of features, including seat heating, climate control, and a massage function, all aimed at ensuring maximum comfort.",
        },


        {
            id: j++,
            img: "/images/v_class/v_class.png",
            pax: "7",
            lug: "7",
            type: "4MATIC",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz V Class",
            title_url: "mb_v_class",
            features: ['vipmv','4mat','leather','reclining','adoors','air','12v','wifi','water'],
            goTO: "/services",
            text:
                "Planning business trips, special occasions and wedding ceremonies, take an important place in our life, and the right choice of the right partner is the most role-playing factor in the quality and effectiveness of fulfilling any of preferred services.\n" +
                "\n" +
                "VIP-Transfers, is an on-going developing company, specialized in diverse service packages, and upholds its reputation by means of a well- grounded, around- the-clock Premium Class Transportation activities, as well as a customer trust and satisfaction, and competitive rates.\n" +
                "\n" +
                "Access to a huge variety of exclusive vehicle fleet, assures valuable customers to experience safe and enjoyable drive. First Class sedans, minivans, minibuses, coaches, retro cars and limousines, even helicopters and planes are at disposal to meet and satisfy our customers’ needs and expectations. On regular basis, technical maintenance of the vehicle fleet, guarantees the clients safety and stress free door-to-door transportation.",
            galeyiImg: [
                { url: "/images/v_class/vclass_1.jpg" },
                { url: "/images/v_class/vclass_2.jpg" },
                { url: "/images/v_class/vclass_3.jpg" },
                { url: "/images/v_class/vclass_4.jpg" },
            ],
            text_top:
                "The very name of Mercedes-Benz V Class is the most luxurious option to be booked while one travelling to Switzerland. As it is the most suitable vehicle to deliver you like the high Swiss qualified services. <br><br> Whether you are staying in Switzerland for a short period of time, or are visiting for your holidays, VIP-Transfers will make sure to provide you with high-quality services in order to make your private ride an enjoyable experience. <br><br> Switzerland – the located country in the heart of Europe, has a lot to offer the families or group travellers: impressive snow-covered mountain sceneries, rich green fields, crystal clear rivers and sparkling lakes. <br><br> Surely, you will need an appropriate vehicle that will not only fit all the passengers with their luggage, but also be restful, powerful and safe. For this type of journeys, VIP-Transfers’ team offers you its brand-new luxury Mercedes-Benz V Class.",
        },
        

        {
            id: j++,
            img: "/images/vito/minivan.png",
            pax: "8",
            lug: "8",
            type: "4MATIC",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz Minivan ",
            title_url: "mb_minivan",
            features: ['vipmv','4mat','leather','reclining','adoors','air','12v','wifi','water'],
            goTO: "/services",
            text:
                "Planning business trips, special occasions and wedding ceremonies, take an important place in our life, and the right choice of the right partner is the most role-playing factor in the quality and effectiveness of fulfilling any of preferred services.\n" +
                "\n" +
                "VIP-Transfers, is an on-going developing company, specialized in diverse service packages, and upholds its reputation by means of a well- grounded, around- the-clock Premium Class Transportation activities, as well as a customer trust and satisfaction, and competitive rates.\n" +
                "\n" +
                "Access to a huge variety of exclusive vehicle fleet, assures valuable customers to experience safe and enjoyable drive. First Class sedans, minivans, minibuses, coaches, retro cars and limousines, even helicopters and planes are at disposal to meet and satisfy our customers’ needs and expectations. On regular basis, technical maintenance of the vehicle fleet, guarantees the clients safety and stress free door-to-door transportation.",
            galeyiImg: [
                { url: "/images/vito/vito_1.jpg" },
                { url: "/images/vito/vito_2.jpg" },
                { url: "/images/vito/vito_3.jpg" },
            ],
            text_top:
                "The Mercedes-Benz Minivan is the light option to gather the family members, friends, business colleagues on most comfort bases. <br><br> This vehicle was created for the aim of spending the special occasions, holiday vacations, or the business trips on a very smooth and relaxing way. <br><br> For these important dates, the selection of the right vehicle is one of the most role-playing things. Thus, let VIP-Transfers be your partner during your stay in Switzerland by providing high quality and effective services in order to fulfil all of your needs. With VIP-Transfers, you will surely have a stress-free and smooth ride to and from your desired location. <br><br> VIP-Transfers is glad to offer you an ideal vehicle to serve for your private transportation, which is well designed for an individual as well as group transfers.",
        },

        
        {
            id: j++,
            img: "/images/sprinter11/sprinter11.png",
            pax: "11",
            lug: "11",
            type: "VIP MINIBUS",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz Sprinter",
            title_url: "mb_sprinter_11",
            features: ['vipmb','12v','air','dvd','wifi','water'],
            galeyiImg: [
                { url: "/images/sprinter11/1.webp" },
                { url: "/images/sprinter11/2.webp" },
                { url: "/images/sprinter11/3.webp" },
                { url: "/images/sprinter11/4.webp" },
                { url: "/images/sprinter11/5.webp" },
                { url: "/images/sprinter11/6.webp" },
                { url: "/images/sprinter11/7.webp" },
                { url: "/images/sprinter11/8.webp" },
                { url: "/images/sprinter11/9.webp" },
                { url: "/images/sprinter11/10.webp" },
                { url: "/images/sprinter11/11.webp" },
            ],
            text_top: "If you’re looking for a reliable and comfortable way to explore Switzerland with your family or friends, look no further than our 11-seat Mercedes Benz Sprinter for private transfers. <br><br> This state-of-the-art vehicle is perfect for group travel, with plenty of space for luggage and all the latest amenities to ensure your journey is as comfortable as possible. <br><br> Vip-transfers.ch operates private transfers from the cities of Zurich, Geneva, Basel, and Bern to the Swiss cantons and neighbouring countries. Therefore, this Mercedes Benz Sprinter is ready to take you from these locations to your preferred destination in complete comfort. <br><br> One of the standout features of the MB Sprinter is its spacious and comfortable interior. <br><br> The seats are arranged in a spacious, theatre-style layout, with plenty of legroom and ample storage space for all your luggage. The vehicle is also equipped with air conditioning so that you can stay cool and comfortable even on the hottest days. In addition to its spacious interior, the MB Sprinter is also packed with the latest technology to make your journey as easy and enjoyable as possible. It comes equipped with a high-quality sound system, free Wi-Fi, as well as USB charging points for all your devices.",
        },

        

        {
            id: j++,
            img: "/images/sprinter13/sprinter.jpg",
            pax: "13",
            lug: "13",
            type: "VIP MINIBUS",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz Sprinter",
            title_url: "mb_sprinter_13",
            features: ['vipmb','12v','leather','air','dvd','wifi','water'],
            galeyiImg: [
                { url: "/images/sprinter13/sprinter_1.webp" },
                { url: "/images/sprinter13/sprinter_2.webp" },
                { url: "/images/sprinter13/sprinter_3.webp" },
                { url: "/images/sprinter13/sprinter_4.webp" },
                { url: "/images/sprinter13/sprinter_5.webp" },
                { url: "/images/sprinter13/sprinter_6.webp" },
                { url: "/images/sprinter13/sprinter_7.webp" },
                { url: "/images/sprinter13/sprinter_8.webp" },
                { url: "/images/sprinter13/sprinter_9.webp" },
            ],
            text_top:
                "Are you planning to visit Switzerland with your family members or friends? <br> VIP-Transfers – the local private agency based in Fribourg, Switzerland invites you to take your happy journey in its luxurious Mercedes-Benz Sprinter. <br><br> The world-known Switzerland is the very desired destination for all travel lovers to have a new life experiences and adventures. Especially if you can share these precious moments with your beloved ones. Then, of course, you will need an appropriate vehicle to be your loyal partner during this trip. <br><br> Our company is specialized in private airport/city transfers from the main cities of Zurich, Geneva, Basel, Bern to the Swiss cantons and neighboring regions. <br><br> Thus, for your special occasion in Switzerland, our skillful team is offering sixteen seated Mercedes-Benz Sprinter. This bus combines comfort, safety, style and pleasure, as well as being perfect for large group private airport transfers.",
        },



        {
            id: j++,
            img: "/images/sprinter16/16.png",
            pax: "16",
            lug: "16",
            type: "VIP MINIBUS",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz Sprinter",
            title_url: "mb_sprinter_16",
            features: ['vipmb','leather','lights','fridge','12v','scenic','air','wifi','water'],
            galeyiImg: [
                { url: "/images/sprinter16/1.jpg" },
                { url: "/images/sprinter16/2.jpg" },
                { url: "/images/sprinter16/3.jpg" },
                { url: "/images/sprinter16/4.jpg" },
                { url: "/images/sprinter16/5.jpg" },
                { url: "/images/sprinter16/6.jpg" },
                { url: "/images/sprinter16/7.jpg" },
                { url: "/images/sprinter16/8.jpg" },
                { url: "/images/sprinter16/9.jpg" },
            ],
            text_top:
                "Switzerland is an incredible place to explore with friends and family. The breathtaking alpine beauty, the lakes, and the fresh air from the mountains can be fully enjoyed with the Mercedes-Benz Sprinter Cabrio that VIP-Transfers offers. If you need a safe, comfortable, and picturesque drive to make your journey more impressive, then Cabrio is the best transfer option for you. Whether you want to book the service for an airport taxi transfer or a private transfer to your holiday resort, we guarantee safety and the provision of high-quality services.<br><br>Traveling with a large group can be challenging. However, we are here to lift the heavy weight from your shoulders and make your group trips more fun. To make your trips and transfers even more enjoyable, we are providing the 8-seat Mercedes-Benz Sprinter Cabrio. This vehicle combines safety, comfort, and pleasure, as well as a special way for you to enjoy your surroundings with an open view.",
        },

        {
            id: j++,
            img: "/images/cabrio/cabrio.png",
            pax: "16",
            lug: "16",
            type: "CABRIO",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz Sprinter",
            title_url: "mb_sprinter_cabrio",
            features: ['vipmb','leather','lights','fridge','12v','scenic','air','wifi','water'],
            galeyiImg: [
                { url: "/images/cabrio/cabrio1.webp" },
                { url: "/images/cabrio/cabrio2.webp" },
                { url: "/images/cabrio/cabrio3.webp" },
                { url: "/images/cabrio/cabrio4.webp" },
                { url: "/images/cabrio/cabrio5.webp" },
                { url: "/images/cabrio/cabrio6.webp" },
                { url: "/images/cabrio/cabrio7.webp" },
                { url: "/images/cabrio/cabrio8.webp" },
            ],
            text_top:
                "Switzerland is an incredible place to explore with friends and family. The breathtaking alpine beauty, the lakes, and the fresh air from the mountains can be fully enjoyed with the Mercedes-Benz Sprinter Cabrio that VIP-Transfers offers. If you need a safe, comfortable, and picturesque drive to make your journey more impressive, then Cabrio is the best transfer option for you. Whether you want to book the service for an airport taxi transfer or a private transfer to your holiday resort, we guarantee safety and the provision of high-quality services.<br><br>Traveling with a large group can be challenging. However, we are here to lift the heavy weight from your shoulders and make your group trips more fun. To make your trips and transfers even more enjoyable, we are providing the 8-seat Mercedes-Benz Sprinter Cabrio. This vehicle combines safety, comfort, and pleasure, as well as a special way for you to enjoy your surroundings with an open view.",
        },

        

        {
            id: j++,
            img: "/images/sprinter19/sprinter19.png",
            pax: "19",
            lug: "19",
            type: "VIP MINIBUS",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz Sprinter",
            title_url: "mb_sprinter_19",
            features: ['vipmb','12v','leather','air','dvd','wifi','water'],
            galeyiImg: [
                { url: "/images/sprinter19/1.webp" },
                { url: "/images/sprinter19/2.webp" },
                { url: "/images/sprinter19/3.webp" },
                { url: "/images/sprinter19/4.webp" },
                { url: "/images/sprinter19/5.webp" },
                { url: "/images/sprinter19/6.webp" },
                { url: "/images/sprinter19/7.webp" },
                { url: "/images/sprinter19/8.webp" },
                { url: "/images/sprinter19/9.webp" },
            ],
            text_top: "If you want elevated comfort for your group transfers, we are happy to introduce our spacious and versatile 19-Seat Mercedes-Benz Sprinter. <br><br> This vehicle is designed with groups in mind and offers unmatched comfort, reliability, and style for your private transfer needs. <br><br> With its impressive seating capacity, the Mercedes-Benz Sprinter is the perfect choice for group travel, whether a corporate event, a family outing, or a tour with friends. The carefully crafted interior provides ample legroom and comfortable seating for all passengers, ensuring a pleasant journey. <br><br> Step inside the Sprinter and experience the epitome of luxury and convenience. The meticulously designed cabin showcases refined materials and modern finishes, creating an ambiance of elegance and sophistication. Equipped with advanced features and amenities, including air conditioning, entertainment systems, and USB charging ports, every passenger can relax and stay connected throughout the ride. <br><br> Safety is our utmost priority at Vip Transfers, and the Mercedes-Benz Sprinter is equipped with cutting-edge safety features. From lane-keeping assist to collision prevention systems, we prioritize your well-being and provide a secure environment for every passenger.",
        },
        
        
        {
            id: j++,
            img: "/images/sprinter_exc/sprinter_exc.png",
            pax: "11",
            lug: "11",
            type: "EXCLUSIVE",
            icon: "/images/fleet/mb.svg",
            iconHover: "/images/fleet/mb-hover.svg",
            title: "Mercedes-Benz Sprinter Exclusive",
            title_url: "mb-sprinter-exclusive",
            features: ['vipmb','table','leather','fridge','air','wifi','water'],
            galeyiImg: [
                { url: "/images/sprinter_exc/1.jpg" },
                { url: "/images/sprinter_exc/2.jpg" },
                { url: "/images/sprinter_exc/3.jpg" },
                { url: "/images/sprinter_exc/4.jpg" },
                { url: "/images/sprinter_exc/5.jpg" },
                { url: "/images/sprinter_exc/6.jpg" },
                { url: "/images/sprinter_exc/7.jpg" },
                { url: "/images/sprinter_exc/8.jpg" },
                { url: "/images/sprinter_exc/9.jpg" },
            ],
            text_top: "For private group transfers in unparalleled comfort, the Mercedes-Benz Sprinter 317 is the ideal choice. <br> <br> Designed to accommodate up to 11 passengers, this comfortable vehicle offers both style and spaciousness, making it perfect for family trips, business travel, or group excursions. <br> <br> Whether you’re embarking on an alpine tour or heading to a key destination, this vehicle ensures you arrive refreshed and relaxed. <br> <br> At Vip-Transfers, we pride ourselves on offering premium private transfer services tailored to your needs. Whether you’re traveling for business or leisure, our Mercedes-Benz Sprinter 317 provides a personalized and exclusive experience for small groups. From airport transfers and hotel pickups to scenic tours of Switzerland, we ensure every detail is taken care of, so you can enjoy your journey without stress.",
        },
        

    ],
};
export default en;